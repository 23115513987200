.medicationMAR-div-with-break {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Defina o número máximo de linhas desejado */
    -webkit-box-orient: vertical;
    max-height: 3.6em; /* (tamanho de fonte x número de linhas desejado) + espaço para o ellipsis */
    width: 250px; /* Defina a largura máxima */
}

.new-medication {
    border-top: 2px solid black; /* Defina a espessura e a cor desejadas */
}
