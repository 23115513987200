.notApplicableConfirmationMessage {
    color: darkgreen;
    margin-top: 0.2rem;
}

.confirmationNotApplicableModal {
    width: 600px;
    min-width: 600px;
    display: block;
}

ul.formData {
    list-style-type: square;
}
