body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
    scroll-behavior: smooth;
    /*Adds smooth scrolling instead of snapping to element*/
}

/* Print Style */
.row-prt2 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.col-prt2-1,
.col-prt2-2,
.col-prt2-3,
.col-prt2-4,
.col-prt2-5,
.col-prt2-6,
.col-prt2-7,
.col-prt2-8,
.col-prt2-9,
.col-prt2-10,
.col-prt2-11,
.col-prt2-12 {
    float: left;
}

.col-prt2-1 {
    grid-column-end: span 1;
}
.col-prt2-2 {
    grid-column-end: span 2;
}
.col-prt2-3 {
    grid-column-end: span 3;
}
.col-prt2-4 {
    grid-column-end: span 4;
}
.col-prt2-5 {
    grid-column-end: span 5;
}
.col-prt2-6 {
    grid-column-end: span 6;
}
.col-prt2-7 {
    grid-column-end: span 7;
}
.col-prt2-8 {
    grid-column-end: span 8;
}
.col-prt2-9 {
    grid-column-end: span 9;
}
.col-prt2-10 {
    grid-column-end: span 10;
}
.col-prt2-11 {
    grid-column-end: span 11;
}
.col-prt2-12 {
    grid-column-end: span 12;
}
