/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
    .fallRiskSectionModal {
        width: 550px;
        min-width: 550px;
        display: block;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
    .fallRiskSectionModal {
        width: 750px;
        min-width: 750px;
        display: block;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
    .fallRiskSectionModal {
        width: 980px;
        min-width: 980px;
        display: block;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
    .fallRiskSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .fallRiskSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

span.icon {
    position: relative;
    top: -2px;
}
