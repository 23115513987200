.reportCard {
    cursor: pointer;
    max-height: 130px;
    min-height: 130px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.reportCard:hover {
    color: green;
    border-color: rgba(0, 0, 100, 0.2);
    -webkit-box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    -moz-box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    opacity: 2;
}

.reportCard .cardDefaultIcon {
    display: inline-block;
}

.reportCard .cardHoverIcon {
    display: none;
}

.reportCard:hover .cardDefaultIcon {
    display: none;
}

.reportCard:hover .cardHoverIcon {
    display: inline-block;
}

.reportCardTitle {
    height: 40px;
    min-height: 40px;
    min-height: 40px;
}

.navLink:link {
    text-decoration: none;
}

.navLink:visited {
    text-decoration: none;
}

.navLink:hover {
    text-decoration: underline;
}

.navLink:active {
    text-decoration: none;
}

.card-container {
    display: flex;
}

.card-image-span {
    vertical-align: top;
    margin-left: 0px; /* Ajuste conforme necessário */
}

.card-text-span {
    /* Adicione estilos adicionais conforme necessário */
}
