/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
    .medicationSectionModal {
        width: 550px;
        min-width: 550px;
        display: block;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
    .medicationSectionModal {
        width: 750px;
        min-width: 750px;
        display: block;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
    .medicationSectionModal {
        width: 980px;
        min-width: 980px;
        display: block;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
    .medicationSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .medicationSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

span.icon {
    position: relative;
    top: -2px;
}

.medicationList thead tr {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(88, 174, 137, 0.5) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.medicationList tbody tr.discontinued {
    color: #aaa;
    text-decoration: line-through;
    text-decoration-color: #aaa;
}

.medicationList td:nth-child(1),
.medicationList th:nth-child(1) > a {
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
    cursor: pointer;
}

.medicationList td:nth-child(2),
.medicationList th:nth-child(2) {
    width: 10%;
}

.medicationList td:nth-child(3),
.medicationList th:nth-child(3) {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
}

.medicationList td:nth-child(4),
.medicationList th:nth-child(4) {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
}

.medicationList td:nth-child(5),
.medicationList th:nth-child(5) {
    width: 11%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
}

.medicationList td:nth-child(6),
.medicationList th:nth-child(6) {
    width: 8%;
}

.medicationList td:nth-child(7),
.medicationList th:nth-child(7) {
    width: 8%;
}

.medicationList td:nth-child(8),
.medicationList th:nth-child(8) {
    width: 8%;
}
