.welcomePanel-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;

    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.welcomePanel-card-container.show {
    opacity: 1;
}

.welcomePanel-rounded-corner {
    height: 320px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.image-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container img {
    max-height: 300px;
    height: auto;
    max-width: 100%;
    object-fit: contain;
}

.textJustify {
    text-align: justify;
    text-justify: inter-word;
}
