@media print {
    .clientSummaryClientSection {
        display: block;
    }
}

@media screen {
    .clientSummaryClientSection {
        display: none;
    }
}
