/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
    .nurseOrdersSectionModal {
        width: 550px;
        min-width: 550px;
        display: block;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
    .nurseOrdersSectionModal {
        width: 750px;
        min-width: 750px;
        display: block;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
    .nurseOrdersSectionModal {
        width: 980px;
        min-width: 980px;
        display: block;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
    .nurseOrdersSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .nurseOrdersSectionModal {
        width: 1100px;
        min-width: 1100px;
        display: block;
    }
}

span.icon {
    position: relative;
    top: -2px;
}

.nurseOrdersList thead tr {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(88, 174, 137, 0.5) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.nurseOrdersList td:nth-child(1),
.nurseOrdersList th:nth-child(1) > a {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
    cursor: pointer;
}

.nurseOrdersList td:nth-child(2),
.nurseOrdersList th:nth-child(2) {
    width: 15%;
}

.nurseOrdersList td:nth-child(3),
.nurseOrdersList th:nth-child(3) {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
}

.nurseOrdersList td:nth-child(4),
.nurseOrdersList th:nth-child(4) {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
}
