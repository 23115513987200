.MARTable tr.highlighted {
    background-color: #e0e0e0;
    cursor: pointer;
}

.MARTable td.medication-cell {
    background-color: #eeeeee !important;
}

.MAR-legend-item {
    text-align: center;
    width: 110px;
    display: inline-block;
    font-size: 12px;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 2px 5px; /* Espaçamento interno para um visual mais agradável */
    margin: 5px; /* Espaçamento externo entre os itens da legenda */
}
