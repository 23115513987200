.my-user-avatar {
    border-radius: 50%;
    border-color: rgb(239, 245, 239);
    border-style: solid;
    border-width: 2px;
}

.my-popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: rgb(88, 174, 137, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
