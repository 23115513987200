.nav-gradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(88, 174, 137, 1) 100%);
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;
}

span.versionNumber {
    font-size: 0.7rem;
}

span.myNavLink {
    background-image: linear-gradient(to right, rgba(88, 174, 137, 1) 50%, rgba(88, 174, 137, 0.1) 50%, rgba(44, 87, 68, 1) 10%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 0px 3px;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
    font-size: 1.1em; /* 1.1em => 16 * 1.5 = 17px */
    font-weight: 500;
}

span.myNavLink:hover {
    background-position: 0;
}

span.myNavLink:before {
    content: "a";
    background: rgba(88, 174, 137, 0.5);
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.3s ease-in-out;
}

span.myNavLink:hover::before {
    width: 100%;
}
