.container-wide {
    max-width: 90%; /* Defina a largura desejada */
}

/*===========*/

@keyframes spin-clockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin-counter-clockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.gear1 {
    animation: spin-clockwise 4s linear infinite;
    transform-origin: center center;
}

.gear2 {
    animation: spin-counter-clockwise 4s linear infinite;
    transform-origin: center center;
}
