/* css wound care table footer */
label.pageLabelAlternative {
    display: none;
}

@media screen and (max-width: 1240px) and (min-width: 992px) {
    label.pageLabel {
        display: none;
    }
    label.pageLabelAlternative {
        display: block;
    }
}

@media screen and (max-width: 783px) and (min-width: 576px) {
    label.pageLabel {
        display: none;
    }
    label.pageLabelAlternative {
        display: block;
    }
}
