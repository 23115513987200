.no-suggestions {
    color: #999;
    padding: 0.5rem;
}
.suggestions {
    position: absolute;
    z-index: 1000;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 62%;
    background-color: white;
}
.suggestions li {
    padding: 0.1rem;
}
.suggestion-active,
.suggestions li:hover {
    background-color: #008f68;
    color: white;
    cursor: pointer;
    font-weight: 700;
}
.suggestions li:not(:last-of-type) {
    aborder-bottom: 1px solid #999;
}
