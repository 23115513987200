@media print {
    .summaryClientSection {
        display: block;
    }
}

@media screen {
    .summaryClientSection {
        display: none;
    }
}
