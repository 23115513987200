@media print {
    /*    .row-prt {
        aflex-shrink: 0;
        apadding-right: calc(1.5rem * 0.5);
        apadding-left: calc(1.5rem * 0.5);
        amargin-top: 0;
    }

    .col-prt-1,
    .col-prt-2,
    .col-prt-3,
    .col-prt-4,
    .col-prt-5,
    .col-prt-6,
    .col-prt-7,
    .col-prt-8,
    .col-prt-9,
    .col-prt-10,
    .col-prt-11,
    .col-prt-12 {
        float: left;
    }

    .col-prt-1 {
        width: 8%;
    }
    .col-prt-2 {
        width: 16%;
    }
    .col-prt-3 {
        width: 25%;
    }
    .col-prt-4 {
        width: 32%;
    }
    .col-prt-5 {
        width: 42%;
    }
    .col-prt-6 {
        width: 48%;
    }
    .col-prt-7 {
        width: 58%;
    }
    .col-prt-8 {
        width: 64%;
    }
    .col-prt-9 {
        width: 75%;
    }
    .col-prt-10 {
        width: 83%;
    }
    .col-prt-11 {
        width: 92%;
    }
    .col-prt-12 {
        width: 100%;
    }
    */
}

.row-prt {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.col-prt-1,
.col-prt-2,
.col-prt-3,
.col-prt-4,
.col-prt-5,
.col-prt-6,
.col-prt-7,
.col-prt-8,
.col-prt-9,
.col-prt-10,
.col-prt-11,
.col-prt-12 {
    float: left;
}

.col-prt-1 {
    grid-column-end: span 1;
}
.col-prt-2 {
    grid-column-end: span 2;
}
.col-prt-3 {
    grid-column-end: span 3;
}
.col-prt-4 {
    grid-column-end: span 4;
}
.col-prt-5 {
    grid-column-end: span 5;
}
.col-prt-6 {
    grid-column-end: span 6;
}
.col-prt-7 {
    grid-column-end: span 7;
}
.col-prt-8 {
    grid-column-end: span 8;
}
.col-prt-9 {
    grid-column-end: span 9;
}
.col-prt-10 {
    grid-column-end: span 10;
}
.col-prt-11 {
    grid-column-end: span 11;
}
.col-prt-12 {
    grid-column-end: span 12;
}
