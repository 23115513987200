.bgimg-home {
    /* Background image */
    background-image: url("/src/assets/Caring-People-Inc-Slide3.png");
    /* Full-screen */
    height: 100%;
    /* Center the background image */
    background-position: center;
    /* Scale and zoom in the image */
    background-size: cover;
    /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
    position: relative;
    /* Add a white text color to all elements inside the .bgimg container */
    color: white;
    /* Add a font */
    font-family: "Courier New", Courier, monospace;
    /* Set the font-size to 25 pixels */
    font-size: 25px;

    position: absolute;
    top: 00px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -10;
    opacity: 0.4;
}
