@media print {
    .summaryHeader {
        display: block;
    }
}

@media screen {
    .summaryHeader {
        display: none;
    }
}
