.visitList thead tr {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(88, 174, 137, 0.5) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.visitList .sort {
    cursor: pointer;
}

.visitList .sort:hover {
    color: rgba(45, 132, 105, 1);
}

/*visitList 7 columns : users role*/
.visitList7 td:nth-child(1),
.visitList7 th:nth-child(1) {
    width: 12%;
}

.visitList7 td:nth-child(2),
.visitList7 th:nth-child(2) {
    width: 12%;
}

.visitList7 td:nth-child(3),
.visitList7 th:nth-child(3) {
    width: 24%;
}

.visitList7 td:nth-child(4),
.visitList7 th:nth-child(4) {
    width: 12%;
}

.visitList7 td:nth-child(5),
.visitList7 th:nth-child(5) {
    width: 12%;
}

.visitList7 td:nth-child(6),
.visitList7 th:nth-child(6) {
    width: 12%;
}

.visitList7 td:nth-child(7),
.visitList7 th:nth-child(7) {
    width: 8%;
}

.visitList7 td:nth-child(8),
.visitList7 th:nth-child(8) {
    width: 8%;
}

/*visitList 8 columns : supervisor role*/
.visitList8 td:nth-child(1),
.visitList8 th:nth-child(1) {
    width: 10%;
}

.visitList8 td:nth-child(2),
.visitList8 th:nth-child(2) {
    width: 10%;
}

.visitList8 td:nth-child(3),
.visitList8 th:nth-child(3) {
    width: 10%;
}

.visitList8 td:nth-child(4),
.visitList8 th:nth-child(4) {
    width: 22%;
}

.visitList8 td:nth-child(5),
.visitList8 th:nth-child(5) {
    width: 8%;
}

.visitList8 td:nth-child(6),
.visitList8 th:nth-child(6) {
    width: 12%;
}

.visitList8 td:nth-child(7),
.visitList8 th:nth-child(7) {
    width: 12%;
}

.visitList8 td:nth-child(8),
.visitList8 th:nth-child(8) {
    width: 8%;
}

.visitList8 td:nth-child(9),
.visitList8 th:nth-child(9) {
    width: 8%;
}

/* Changing bootstrap input glow color*/
.form-control:focus {
    border-color: rgba(88, 174, 137, 1) !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(88, 174, 137, 0.5) !important;
}

/*disabled records*/
.itemDisabled {
    color: darkslategrey;
    text-decoration: line-through;
    font-style: italic;
}
