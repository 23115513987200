.assessmentCard {
    cursor: pointer;
}

.assessmentCard:hover {
    -webkit-box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    -moz-box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    box-shadow: 0px 0px 15px 4px rgba(11, 74, 55, 0.32);
    zoom: 100%;
}

.navLink:link {
    text-decoration: none;
}

.navLink:visited {
    text-decoration: none;
}

.navLink:hover {
    text-decoration: underline;
}

.navLink:active {
    text-decoration: none;
}
