.completed_icon g {
    fill: red;
}

.icon path {
    stroke: red;
    stroke-width: 10px;
}

.assessmentCard {
    height: 120px;
    min-height: 120px;
    min-height: 120px;
}

.assessmentCardTitle {
    height: 40px;
    min-height: 40px;
    min-height: 40px;
}

.form-check-input:checked {
    background-color: #2f8754;
    border-color: #095c144d;
}

.form-check-input:focus {
    -webkit-box-shadow: 0px 0px 7px 3px rgba(8, 145, 40, 0.5);
    -moz-box-shadow: 0px 0px 7px 3px rgba(8, 145, 40, 0.5);
    box-shadow: 0px 0px 7px 3px rgba(8, 145, 40, 0.5);
    border-color: #095c144d;
}
