.medicationMAR-value {
    font-size: 11px !important;
    min-width: 40px;
}

.medicationMAR-value-R {
    background-color: lightcoral !important;
}

.medicationMAR-value-NA {
    background-color: lightgray !important;
}

.medicationMAR-value-DC {
    background-color: lightyellow !important;
}

.medicationMAR-value-H {
    background-color: lightskyblue !important;
}

.medicationMAR-value-NT {
    background-color: orange !important;
}
