/* styles.css */
.MARLogTable.table {
    width: 100%;
    afont-size: smaller;
}

.MARLogTable td {
    font-size: smaller;
}

/*visitList 3 columns : users role*/
.MARLogTable td:nth-child(1),
.MARLogTable th:nth-child(1) {
    width: 15%;
}

.MARLogTable td:nth-child(2),
.MARLogTable th:nth-child(2) {
    width: 15%;
}

.MARLogTable td:nth-child(3),
.MARLogTable th:nth-child(3) {
    width: 25%;
}

.MARLogTable td:nth-child(4),
.MARLogTable th:nth-child(4) {
    width: 15%;
}

.MARLogTable td:nth-child(5),
.MARLogTable th:nth-child(5) {
    width: 30%;
}
