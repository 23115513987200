.visitCompletionComplianceList thead tr {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(88, 174, 137, 0.5) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.visitCompletionComplianceList .sort {
    cursor: pointer;
}

.visitCompletionComplianceList .sort:hover {
    color: rgba(45, 132, 105, 1);
}

/*visitCompletionComplianceList 8 columns : users role*/
.visitCompletionComplianceList8 td:nth-child(1),
.visitCompletionComplianceList8 th:nth-child(1) {
    width: 11%;
}

.visitCompletionComplianceList8 td:nth-child(2),
.visitCompletionComplianceList8 th:nth-child(2) {
    width: 11%;
}

.visitCompletionComplianceList8 td:nth-child(3),
.visitCompletionComplianceList8 th:nth-child(3) {
    width: 10%;
}

.visitCompletionComplianceList8 td:nth-child(4),
.visitCompletionComplianceList8 th:nth-child(4) {
    width: 9%;
}

.visitCompletionComplianceList8 td:nth-child(5),
.visitCompletionComplianceList8 th:nth-child(5) {
    width: 9%;
}

.visitCompletionComplianceList8 td:nth-child(6),
.visitCompletionComplianceList8 th:nth-child(6) {
    width: 9%;
}
.visitCompletionComplianceList8 td:nth-child(7),
.visitCompletionComplianceList8 th:nth-child(7) {
    width: 11%;
}
.visitCompletionComplianceList8 td:nth-child(8),
.visitCompletionComplianceList8 th:nth-child(8) {
    width: 13%;
}
.visitCompletionComplianceList8 td:nth-child(9),
.visitCompletionComplianceList8 th:nth-child(9) {
    width: 17%;
}

/* styles.css or DatePickerStyles.css */
.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--in-range,
.custom-datepicker .react-datepicker__day:hover {
    background-color: #3caf8a !important;
  }

.custom-width {
    width: 175px;
  }